import React, { useState } from "react";
import YouTube from "react-youtube";

import { Button, Modal } from "antd";
const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <>
      <section className="bg-gray-50">
        <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
          <div className="mx-auto max-w-xl text-center">
            <h1 className="text-3xl font-extrabold sm:text-5xl">
              Stay Connected with
              <strong className="font-extrabold text-red-700 sm:block">
                {" "}
                Every Islamic Event{" "}
              </strong>
            </h1>

            <p className="mt-4 sm:text-xl/relaxed">
              Never Miss an Important Date with the Jaffery Calendar
            </p>

            <div className="mt-8 flex flex-wrap justify-center gap-4">
              <a
                className="block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto"
                href="#started"
              >
                Get Started
              </a>

              <div
                style={{ cursor: "pointer" }}
                onClick={showModal}
                className="block w-full rounded px-12 py-3 text-sm font-medium text-red-600 shadow hover:text-red-700 focus:outline-none focus:ring active:text-red-500 sm:w-auto"
                href="#"
              >
                Learn More
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title="Jaffery Calendar Tutorial"
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={[]}
      >
        <YouTube videoId="_TGB1KLbNUk" opts={opts} />
      </Modal>
    </>
  );
};

export default Hero;
