import React from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import toast, { Toaster } from "react-hot-toast";
// import Header from "./Header";
import "./helper.css";
const Feedback = () => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    feedback: "",
  };
  const [feedback, setfeedback] = React.useState(initialState);
  const onChange = (e) => {
    setfeedback({ ...feedback, [e.target.name]: e.target.value });
  };
  const supabase = useSupabaseClient(); // talk to supabase!
  const handleClick = async (e) => {
    const { error } = await supabase.from("feedback").insert({
      firstName: feedback.firstName,
      lastName: feedback.lastName,
      email: feedback.email,
      country: feedback.country,
      feedback: feedback.feedback,
    });
    if (error) {
      toast.error("An error occurred!");
      console.error("Error inserting/updating user profile:", error);
    }
    setfeedback(initialState);
    toast.success("Thankyou for your feedback");
    // .eq("email", user.email);
  };

  return (
    <div className="md:mt-16 mb-4 ContainerMargin">
      {/* <Header /> */}
      <div className="flex justify-center items-center w-screen h-screen bg-white">
        <div className="container mx-auto my-4 px-4 lg:px-20">
          <div className="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
            <div className="flex">
              <h1 className="font-bold uppercase text-5xl">
                Send us a <br /> Feedback
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
              <input
                onChange={(e) => onChange(e)}
                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                value={feedback.firstName}
                name="firstName"
                placeholder="First Name "
              />
              <input
                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                onChange={(e) => onChange(e)}
                name="lastName"
                value={feedback.lastName}
                placeholder="Last Name "
              />
              <input
                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                onChange={(e) => onChange(e)}
                type="email"
                value={feedback.email}
                name="email"
                placeholder="Email"
              />
              <input
                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                value={feedback.country}
                name="country"
                onChange={(e) => onChange(e)}
                placeholder="Country"
              />
            </div>
            <div className="my-4">
              <textarea
                placeholder="Feedback"
                name="feedback"
                value={feedback.feedback}
                onChange={(e) => onChange(e)}
                className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              ></textarea>
            </div>
            <div className="my-2 w-1/2 lg:w-1/4">
              <button
                onClick={handleClick}
                className="uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                      focus:outline-none focus:shadow-outline"
              >
                Send Feedback
              </button>
            </div>
          </div>

          <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-2 ml-auto bg-pcolor rounded-2xl">
            <div className="flex flex-col text-white-100">
              <h1 className="font-bold uppercase text-4xl my-4">
                We Value Your Feedback
              </h1>
              <p className="text-gray-400">
                Your feedback is essential in helping us improve the Jaffery
                Calendar. Whether you have suggestions, issues, or positive
                experiences to share, we want to hear from you.
              </p>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-map-marker-alt pt-2 pr-2" />
                </div>
                {/* <div className="flex flex-col">
                  <h2 className="text-2xl">Main Office</h2>
                  <p className="text-gray-400">India</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
