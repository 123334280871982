import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="legaldoc">
        <h2 className="mb-4 uppercase text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Jaffery Calendar PRIVACY POLICY
        </h2>
        <h2 className="mb-4 mt-4 text-xl font-para text-gray-900 dark:text-gray-400 ">
          Last updated: June 24, 2024
        </h2>
        <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          Welcome to the Jaffery Calendar, your ultimate source for Islamic
          dates, events, and reminders. This Privacy Policy explains how we
          collect, use, disclose, and protect your information when you use our
          services.
        </p>
        {/* <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          This Privacy Policy applies to the Estuary website, and any other
          related services (collectively, the "Service"). By using the Service,
          you agree to the terms of this Privacy Policy.
        </p> */}
        <h2 className=" mt-4 font-para text-xl text-gray-900 dark:text-gray-400 ">
          Information We Collect
        </h2>
        <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          Email address (for event notifications and Google Calendar
          integration).
        </p>
        <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          Information about your interactions with our website and app (e.g.,
          pages viewed, features used).
        </p>
        <h2 className=" mt-4 font-para text-xl text-gray-900 dark:text-gray-400 ">
          Use of Information
        </h2>
        <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          To Provide and Improve Our Services:
          <ol className=" space-y-1 text-black-600 list-decimal list-inside dark:text-gray-400">
            <li>To deliver event notifications and updates.</li>
            <li>To integrate events with your Google Calendar.</li>
            <li>To enhance the user experience on our website and app.</li>
          </ol>
        </p>
        <p className="mb-2 font-medium text-black-900 md:text-lg dark:text-black-600">
          To Communicate with You:
          <ol className=" space-y-1 text-black-600 list-decimal list-inside dark:text-gray-400">
            <li>
              To send you important notifications and updates about Islamic
              events and holidays.
            </li>
          </ol>
        </p>
        <h2 className=" mt-4 font-para text-xl text-gray-900 dark:text-gray-400 ">
          Sharing of Information
        </h2>
        <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          We may share your information with third parties for the following
          purposes:
        </p>

        <ol className=" space-y-1 text-black-600 list-decimal list-inside dark:text-gray-400">
          <li>
            <span className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
              Service Providers:
              <ul>
                <li>
                  Third-party service providers who assist us in operating our
                  website, app, and conducting our business.
                </li>
              </ul>
            </span>
          </li>
          <li>
            <span className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
              Legal Requirements:
              <ul>
                <li>
                  If required by law, we may disclose your information to comply
                  with legal obligations.
                </li>
              </ul>
            </span>
          </li>
        </ol>
        <h2 className=" mt-4 font-para text-xl text-gray-900 dark:text-gray-400 ">
          Disclosure of Your Personal Data
        </h2>
        <ol className=" space-y-1 text-black-600 list-decimal list-inside dark:text-gray-400">
          <li>
            <span className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
              Disclosure:
              <ul>
                <li>
                  Jaffery Calendar use and transfer to any other app of
                  information received from Google APIs will adhere to{" "}
                  <a
                    style={{ color: "blue" }}
                    target="_blank"
                    href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                  >
                    Google API Services
                  </a>{" "}
                  User Data Policy, including the Limited Use requirements
                </li>
              </ul>
            </span>
          </li>
        </ol>
        <h2 className=" mt-4 font-para text-xl text-gray-900 dark:text-gray-400 ">
          Security
        </h2>
        <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          We implement a variety of security measures to maintain the safety of
          your personal information. However, no method of transmission over the
          internet or method of electronic storage is 100% secure.
        </p>
        <h2 className=" mt-4 font-para text-xl text-gray-900 dark:text-gray-400 ">
          Changes to This Privacy Policy
        </h2>
        <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on our website. You
          are advised to review this Privacy Policy periodically for any
          changes.
        </p>
        <h2 className=" mt-4 font-para text-xl text-gray-900 dark:text-gray-400 ">
          Contact Us
        </h2>
        <p className="mb-2 font-medium text-black-600 md:text-lg dark:text-black-600">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at hasnainsayyed485@gmail.com
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
