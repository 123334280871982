import React from "react";

const Stepper = () => {
  return (
    <>
      <section className="p-6 dark:bg-gray-900 dark:text-gray-100">
        <div className="container mx-auto">
          <span className="block mb-2 text-xs font-medium tracki text-center uppercase text-violet-400">
            How it works
          </span>
          <h2 className="text-5xl font-bold text-center dark:text-gray-50">
            Adding events to your calendar is easy
          </h2>
          <div className="grid gap-6 my-16 lg:grid-cols-3">
            <div className="flex flex-col p-8 space-y-4 rounded-md bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full bg-violet-400 text-gray-100">
                1.
              </div>
              <p className="text-2xl font-semibold text-gray-100">
                {/* Login and Grant Access
                <br /> */}
                Sign in with your Google account to authorize Jaffery Calendar
                to access your Google Calendar.
              </p>
            </div>
            <div className="flex flex-col p-8 space-y-4 rounded-md bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full bg-violet-400 text-gray-100">
                2.
              </div>
              <p className="text-2xl text-gray-100 font-semibold">
                {/* Select Date and Create Event
                <br />  */}
                Select the desired date and click "Create."
              </p>
            </div>
            <div className="flex flex-col p-8 space-y-4 rounded-md bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full bg-violet-400 text-gray-100">
                3.
              </div>
              <p className="text-2xl font-semibold text-gray-100">
                Your events are instantly synced with Google Calendar.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stepper;
