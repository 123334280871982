import React, { useEffect } from "react";
import { Modal } from "antd";
import { Calendar } from "antd";
import moment from "moment";
const CalendarComponent = () => {
  const [Caldata, setCalData] = React.useState({});
  const [load, setload] = React.useState(false);
  const getCalendarData = async (month, year) => {
    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      req_month: month,
      req_year: year,
    });

    let response = await fetch(
      "https://jaffery-calendar.vercel.app/api/getCalendar",
      {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      }
    );

    let data = await response.json();

    return JSON.parse(data);
  };
  useEffect(() => {
    const date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    setselected(moment(`${year}-${month}-${date.getDate()}`));
    setload(true);
    getCalendarData(month, year).then((res) => {
      setCalData(res);
      setload(false);
    });
  }, []);
  const [isModalOpen, setIsModalOpen] = React.useState({
    open: false,
    clicked: "",
    date: "",
  });

  const [selectedate, setselected] = React.useState({
    value: "",
  });
  const showModal = (e, clicked, date) => {
    e.preventDefault();
    setIsModalOpen({ open: true, clicked: clicked, date: date });
  };

  const handleCancel = () => {
    setIsModalOpen({ open: false, clicked: "", date: "" });
  };
  function dateCellRender(value) {
    const dateKey = `${value.date()}`;
    const month = value["$M"] + 1;
    return (
      <ul className="events">
        {Caldata[dateKey] ? (
          <li
            onClick={(e) =>
              showModal(
                e,
                Caldata[dateKey],
                `${dateKey}/${month}/${value["$y"]}`
              )
            }
          >
            <span className={`event-${"normal"} text-pcolor`}>●&nbsp;</span>
            {Caldata[dateKey]}
          </li>
        ) : (
          ""
        )}
      </ul>
    );
  }
  const Setvalue = (value) => {
    setload(true);

    setselected({ value });
    getCalendarData(value["$M"] + 1, value["$y"]).then((res) => {
      setCalData(res);
      setload(false);
    });
  };

  return (
    <>
      <div className="mt-32 md:mt-32 mb-4 overflow-x-auto">
        {!load ? (
          <Calendar
            onPanelChange={(value) => Setvalue(value)}
            cellRender={dateCellRender}
            mode={"month"}
            value={selectedate.value}
            onSelect={(value) => setselected({ value })}
          />
        ) : (
          <center>
            <h3>Loading...</h3>
          </center>
        )}
        <Modal
          title={isModalOpen.date}
          open={isModalOpen.open}
          onCancel={handleCancel}
          onOk={handleCancel}
          footer={[]}
        >
          <p>{isModalOpen.clicked}</p>
        </Modal>
      </div>
    </>
  );
};

export default CalendarComponent;
