import React, { useEffect, useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import { DatePicker, Space } from "antd";
import { Tooltip } from "antd";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { loginSuccess } from "../reducers/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

const DatePickerComponent = ({ session, setloading, loading }) => {
  const { user } = useSelector((state) => state.user);
  const currMonth = getCurrentMonthAndYear();
  // const [currMonth, setCurrMonth] = React.useState();
  const [ReqMonth, setReqMonth] = React.useState("");
  function getCurrentMonthAndYear() {
    const date = new Date();
    const year = date.getFullYear(); // Get the current year.
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
  }
  const supabase = useSupabaseClient(); // talk to supabase!
  async function googleSignIn(e) {
    e.preventDefault();
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        scopes: "https://www.googleapis.com/auth/calendar.events",
      },
    });
    if (error) {
      console.error("Error signing in:", error);
    }
  }
  const dispatch = useDispatch();

  async function createCalendarEvent(e) {
    e.preventDefault();
    setloading(true);
    const date = new Date();
    const cur_year = date.getFullYear(); // Get the current year.
    const cur_month = date.getMonth() + 1;
    const cur_date = date.getDate();
    var curr = new Date(`${cur_year}/${cur_month}/${cur_date}`);
    if (ReqMonth === "") {
      setloading(false);
      return toast.error("Please select month");
    }
    var bodyContent = JSON.stringify({
      curr_date: `${cur_year}/${cur_month}/${cur_date}`,
      req_date: ReqMonth,
      refresh_token: session.refresh_token,
    });
    if (user.req_date) {
      let date1 = new Date(ReqMonth);
      let date2 = new Date(user.req_date);
      //lower bound
      let year1 = date1.getFullYear();
      let month1 = date1.getMonth(); // Note: getMonth() returns 0-11
      let year2 = date2.getFullYear();
      let month2 = date2.getMonth();
      let newMonth = month2 + 2;
      let newYear = year2;
      // Adjust the year if the new month overflows
      if (newMonth > 11) {
        newMonth -= 12;
        newYear += 1;
      }
      // Compare the year and month
      if (year1 < year2 || (year1 === year2 && month1 <= month2)) {
        setloading(false);
        return toast.error(
          "You have already added this month. Please select a future month"
        );
      }
      //upper bound
      bodyContent = JSON.stringify({
        curr_date:
          curr > date2
            ? `${cur_year}/${cur_month}/${cur_date}`
            : `${newYear}/${newMonth}/${date2.getDate()}`,
        req_date: ReqMonth,
        refresh_token: session.refresh_token,
      });
    }
    var headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.provider_token}`,
    };

    await fetch("https://jaffery-calendar.vercel.app/api/addCalendar", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        let response = await res.json();
        if (response.error)
          return toast.error("An error occurred! Please try again ");

        const { error } = await supabase
          .from("users")
          .update({ req_date: ReqMonth })
          .eq("email", user.email);
        dispatch(
          loginSuccess({
            ...user,
            req_date: ReqMonth,
          })
        );
        if (error) {
          console.error("Error inserting/updating user profile:", error);
        }
        setloading(false);
        toast.success(response.message);
      })
      .catch(async (err) => {
        setloading(false);
        if (err instanceof TypeError) {
          const date = new Date();
          date.setMonth(date.getMonth() + 2); // Add 2 to the current month since getMonth() is zero-indexed
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
          const day = date.getDate();
          const formattedDate = `${year}/${month}/${day}`;
          const { error } = await supabase
            .from("users")
            .update({
              req_date: formattedDate,
            })
            .eq("email", user.email);

          if (error) {
            console.error("Error updating user:", error);
          }
        } else {
          console.error("Error response:", err.response ? err.response : err);
        }
      });
  }
  const handleEndDate = async (value, dateString) => {
    if (value[1] == null) return;
    let month = value[1]["$M"] + 1;
    let day = value[1]["$D"];
    let year = value[1]["$y"];
    setReqMonth(`${year}/${month}/${day}`);
  };

  return (
    <>
      <div id="started" className="py-4 flex-col justify-center sm:py-4">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="relative px-4 py-10 bg-white-100 mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
            <div className="max-w-md mx-auto">
              <div className="flex items-center space-x-5">
                <div className="h-14 w-14 bg-yellow-200 rounded-full flex flex-shrink-0 justify-center items-center text-yellow-500 text-2xl font-mono">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM11 13V17H6V13H11ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path>
                  </svg>
                </div>
                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                  <h2 className="leading-relaxed">Create Event</h2>
                  <p className="text-sm text-gray-500 font-normal leading-relaxed">
                    Add events directly to your google calendar
                  </p>
                </div>
              </div>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div
                    style={{ width: "100%" }}
                    className="flex items-center space-x-4"
                  >
                    <div style={{ width: "100%" }} className="flex flex-col">
                      <label className="leading-loose flex items-center mb-2">
                        Choose End-Month&nbsp;
                        <Tooltip
                          placement="top"
                          title={
                            "Choose the end month for the events you want to add to your Google Calendar. "
                          }
                        >
                          <svg
                            className=" h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path>
                          </svg>
                        </Tooltip>
                      </label>
                      <div
                        style={{ width: "100%" }}
                        className="relative focus-within:text-gray-600 text-gray-400"
                      >
                        <RangePicker
                          allowClear
                          style={{ width: "100%" }}
                          picker="month"
                          onChange={(date, dateString) =>
                            handleEndDate(date, dateString)
                          }
                          defaultValue={dayjs(currMonth, "YYYY-MM")}
                          disabled={[true, false]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-4 flex items-center space-x-4">
                  {session && session.user.user_metadata.email ? (
                    <>
                      {/* <button
                        onClick={() => setReqMonth("")}
                        className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none"
                      >
                        <svg
                          className="w-6 h-6 mr-3"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>{" "}
                        Cancel
                      </button> */}
                      <button
                        onClick={createCalendarEvent}
                        className="bg-blue-500 flex justify-center items-center w-full text-white-100 px-4 py-3 rounded-md focus:outline-none"
                      >
                        Create
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={googleSignIn}
                      className="bg-red-800 flex justify-center items-center w-full text-white-100 px-4 py-3 rounded-md focus:outline-none"
                    >
                      <svg
                        className="w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M3.06364 7.50914C4.70909 4.24092 8.09084 2 12 2C14.6954 2 16.959 2.99095 18.6909 4.60455L15.8227 7.47274C14.7864 6.48185 13.4681 5.97727 12 5.97727C9.39542 5.97727 7.19084 7.73637 6.40455 10.1C6.2045 10.7 6.09086 11.3409 6.09086 12C6.09086 12.6591 6.2045 13.3 6.40455 13.9C7.19084 16.2636 9.39542 18.0227 12 18.0227C13.3454 18.0227 14.4909 17.6682 15.3864 17.0682C16.4454 16.3591 17.15 15.3 17.3818 14.05H12V10.1818H21.4181C21.5364 10.8363 21.6 11.5182 21.6 12.2273C21.6 15.2727 20.5091 17.8363 18.6181 19.5773C16.9636 21.1046 14.7 22 12 22C8.09084 22 4.70909 19.7591 3.06364 16.4909C2.38638 15.1409 2 13.6136 2 12C2 10.3864 2.38638 8.85911 3.06364 7.50914Z"></path>
                      </svg>
                      &nbsp;Sign in
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default DatePickerComponent;
