import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Home from "./components/Home";
import Feedback from "./components/Feedback";
import About from "./components/About";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CalendarComponent from "./components/Calendar";
import { loginSuccess } from "./reducers";
import { useDispatch } from "react-redux";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
const App = () => {
  const supabase = useSupabaseClient(); // talk to supabase!
  // const { isLoading } = useSessionContext();
  const dispatch = useDispatch();
  React.useEffect(() => {
    // supabase.auth.getSession().then(({ data: { session } }) => {
    //   // setSession(session);
    //   console.log(session);
    //   // loginSuccess({
    //   //   email: session.email,
    //   //   name: session.user_metadata.full_name,
    //   //   img_url: session.user_metadata.avatar_url,
    //   // });
    // });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      // setSession(session);
      handleAuthChange(_event, session);
    });
    const handleAuthChange = async (event, session) => {
      if (event === "SIGNED_IN") {
        const user = session.user;
        // setUser(user);
        await supabase
          .from("users")
          .select()
          .eq("email", user.email)
          .then(async (users) => {
            if (users.data.length == 0) {
              const { data, error } = await supabase.from("users").upsert(
                [
                  {
                    // id: user.id,
                    email: user.email,
                    name: user.user_metadata.full_name,
                    img_url: user.user_metadata.avatar_url,
                  },
                ],
                { onConflict: "email" }
              );
              if (error) {
                console.error("Error inserting/updating user profile:", error);
              } else {
                console.log("User profile inserted/updated:", data);
              }
              window.location.reload();
            } else {
              dispatch(
                loginSuccess({
                  email: users.data[0].email,
                  name: users.data[0].name,
                  img_url: users.data[0].img_url,
                  req_date: users.data[0].req_date,
                })
              );
            }
          });
      }
    };
    return () => subscription.unsubscribe();
  }, []);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/calendar" element={<CalendarComponent />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
