import React from "react";
import Hero from "./Hero";
import DatePickerComponent from "./DatePicker";
import Stepper from "./Stepper";
import { useSession, useSessionContext } from "@supabase/auth-helpers-react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./helper.css";
const Home = () => {
  const { isLoading } = useSessionContext();

  const session = useSession();

  const [loading, setloading] = React.useState(false);
  return (
    <>
      <Hero />
      {loading && (
        <div className="loader-overlay">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 50,
                }}
                spin
              />
            }
          />
        </div>
      )}
      <Stepper />
      <DatePickerComponent
        setloading={setloading}
        loading={loading}
        isLoading={isLoading}
        session={session}
      />
    </>
  );
};

export default Home;
