import React, { useState, useEffect } from "react";

import { useSupabaseClient } from "@supabase/auth-helpers-react";
import logo from "../asset/web-logo.png";
import { useSession } from "@supabase/auth-helpers-react";
function Header() {
  const [activeLink, setActiveLink] = useState(null);
  const [scrollActive, setScrollActive] = useState(false);
  const user = useSession();

  const supabase = useSupabaseClient(); // talk to supabase!
  const handleLogout = async (e) => {
    await supabase.auth.signOut();
    window.location.href = "/";
  };
  return (
    <>
      <header
        className={
          "fixed top-0 w-full  z-30 transition-all " +
          (scrollActive ? " shadow-md pt-0" : " pt-1")
        }
        style={{
          backdropFilter: "saturate(180%) blur(20px)",
          background: "rgba(251, 251, 253, .8)",
          // background: "rgba( 255, 255, 255, 0.1 )",
          // backdropFilter: "blur( 4px )",
        }}
      >
        <nav className="max-w-screen-xl px-6 sm:px-8 lg:px-16 mx-auto grid grid-flow-col py-3 sm:py-0">
          <div className="col-start-1 col-end-2 flex items-center">
            <a href="/" style={{ position: "relative", display: "flex" }}>
              <img
                height={100}
                width={100}
                alt="Header logo"
                className=" w-100 h-50"
                src={logo}
              />
              <svg
                height="100"
                width="100"
                style={{ left: "42px", top: "-20px" }}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute"
              >
                <path
                  d="M149.709 194.21a.307.307 0 0 1 .582 0l.57 1.734c.03.092.103.165.195.195l1.734.57c.28.093.28.489 0 .582l-1.734.57a.306.306 0 0 0-.195.195l-.57 1.734a.307.307 0 0 1-.582 0l-.57-1.734a.306.306 0 0 0-.195-.195l-1.734-.57a.307.307 0 0 1 0-.582l1.734-.57a.306.306 0 0 0 .195-.195l.57-1.734Z"
                  fill="#410CD9"
                ></path>
                <path
                  opacity="0.5"
                  d="M108.806 193.14a.204.204 0 0 1 .388 0l.38 1.156c.02.061.069.11.13.13l1.156.38a.204.204 0 0 1 0 .388l-1.156.38a.205.205 0 0 0-.13.13l-.38 1.156a.204.204 0 0 1-.388 0l-.38-1.156a.205.205 0 0 0-.13-.13l-1.156-.38a.204.204 0 0 1 0-.388l1.156-.38a.205.205 0 0 0 .13-.13l.38-1.156ZM62.16 182.245a.357.357 0 0 1 .68 0l.664 2.023c.036.108.12.192.228.228l2.023.665a.357.357 0 0 1 0 .678l-2.023.665a.358.358 0 0 0-.228.228l-.665 2.023a.357.357 0 0 1-.678 0l-.665-2.023a.358.358 0 0 0-.228-.228l-2.023-.665a.357.357 0 0 1 0-.678l2.023-.665a.358.358 0 0 0 .228-.228l.665-2.023Z"
                  fill="#410CD9"
                ></path>
                <path
                  d="M201.418 159.421c.185-.561.979-.561 1.164 0l1.14 3.467c.06.184.206.33.39.39l3.467 1.14c.561.185.561.979 0 1.164l-3.467 1.14a.611.611 0 0 0-.39.39l-1.14 3.467c-.185.561-.979.561-1.164 0l-1.14-3.467a.611.611 0 0 0-.39-.39l-3.467-1.14c-.561-.185-.561-.979 0-1.164l3.467-1.14a.611.611 0 0 0 .39-.39l1.14-3.467Z"
                  fill="#410CD9"
                ></path>
                <path
                  opacity="0.5"
                  d="M68.418 160.421c.185-.561.979-.561 1.164 0l1.14 3.467c.06.184.206.33.39.39l3.467 1.14c.561.185.561.979 0 1.164l-3.466 1.14a.611.611 0 0 0-.391.39l-1.14 3.467c-.185.561-.979.561-1.164 0l-1.14-3.467a.611.611 0 0 0-.39-.39l-3.467-1.14c-.561-.185-.561-.979 0-1.164l3.467-1.14a.611.611 0 0 0 .39-.39l1.14-3.467Z"
                  fill="#410CD9"
                ></path>
                <path
                  opacity="0.25"
                  d="M184.612 149.28a.409.409 0 0 1 .776 0l.76 2.312c.04.123.137.22.26.26l2.312.76a.409.409 0 0 1 0 .776l-2.312.76a.406.406 0 0 0-.26.26l-.76 2.312a.409.409 0 0 1-.776 0l-.76-2.312a.406.406 0 0 0-.26-.26l-2.312-.76a.409.409 0 0 1 0-.776l2.312-.76a.406.406 0 0 0 .26-.26l.76-2.312Z"
                  fill="#410CD9"
                ></path>
                <path
                  d="M115.709 153.21a.307.307 0 0 1 .582 0l.57 1.734c.03.092.103.165.195.195l1.734.57c.28.093.28.489 0 .582l-1.734.57a.306.306 0 0 0-.195.195l-.57 1.734a.307.307 0 0 1-.582 0l-.57-1.734a.306.306 0 0 0-.195-.195l-1.734-.57a.307.307 0 0 1 0-.582l1.734-.57a.306.306 0 0 0 .195-.195l.57-1.734ZM194.161 120.245a.357.357 0 0 1 .678 0l.665 2.023a.36.36 0 0 0 .228.228l2.023.665a.357.357 0 0 1 0 .678l-2.023.665a.36.36 0 0 0-.228.228l-.665 2.023a.357.357 0 0 1-.678 0l-.665-2.023a.36.36 0 0 0-.228-.228l-2.023-.665a.357.357 0 0 1 0-.678l2.023-.665a.36.36 0 0 0 .228-.228l.665-2.023Z"
                  fill="#410CD9"
                ></path>
                <path
                  opacity="0.25"
                  d="M168.806 123.14a.204.204 0 0 1 .388 0l.38 1.156c.02.061.069.11.13.13l1.156.38a.204.204 0 0 1 0 .388l-1.156.38a.205.205 0 0 0-.13.13l-.38 1.156a.204.204 0 0 1-.388 0l-.38-1.156a.205.205 0 0 0-.13-.13l-1.156-.38a.204.204 0 0 1 0-.388l1.156-.38a.205.205 0 0 0 .13-.13l.38-1.156Z"
                  fill="#410CD9"
                ></path>
                <path
                  d="M51.16 116.245a.357.357 0 0 1 .68 0l.664 2.023c.036.108.12.192.228.228l2.023.665a.357.357 0 0 1 0 .678l-2.023.665a.358.358 0 0 0-.228.228l-.665 2.023a.357.357 0 0 1-.678 0l-.665-2.023a.358.358 0 0 0-.228-.228l-2.023-.665a.357.357 0 0 1 0-.678l2.023-.665a.358.358 0 0 0 .228-.228l.665-2.023ZM3.16 132.245a.357.357 0 0 1 .68 0l.664 2.023c.036.108.12.192.228.228l2.023.665a.357.357 0 0 1 0 .678l-2.023.665a.358.358 0 0 0-.228.228l-.665 2.023a.357.357 0 0 1-.678 0l-.665-2.023a.358.358 0 0 0-.228-.228l-2.023-.665a.357.357 0 0 1 0-.678l2.023-.665a.358.358 0 0 0 .228-.228l.665-2.023Z"
                  fill="#410CD9"
                ></path>
                <path
                  opacity="0.5"
                  d="M181.258 79.175a.255.255 0 0 1 .484 0l.475 1.445a.257.257 0 0 0 .163.163l1.445.475a.255.255 0 0 1 0 .484l-1.445.475a.257.257 0 0 0-.163.163l-.475 1.445a.255.255 0 0 1-.484 0l-.475-1.445a.257.257 0 0 0-.163-.163l-1.445-.475a.255.255 0 0 1 0-.484l1.445-.475a.257.257 0 0 0 .163-.163l.475-1.445ZM21.258 79.175a.255.255 0 0 1 .484 0l.475 1.445a.255.255 0 0 0 .163.163l1.445.475a.255.255 0 0 1 0 .484l-1.445.475a.255.255 0 0 0-.163.163l-.475 1.445a.255.255 0 0 1-.484 0l-.475-1.445a.255.255 0 0 0-.163-.163l-1.445-.475a.255.255 0 0 1 0-.484l1.445-.475a.255.255 0 0 0 .163-.163l.475-1.445ZM79.258 105.175a.255.255 0 0 1 .484 0l.475 1.445a.257.257 0 0 0 .163.163l1.445.475a.255.255 0 0 1 0 .484l-1.445.475a.257.257 0 0 0-.163.163l-.475 1.445a.255.255 0 0 1-.484 0l-.475-1.445a.257.257 0 0 0-.163-.163l-1.445-.475a.255.255 0 0 1 0-.484l1.445-.475a.257.257 0 0 0 .163-.163l.475-1.445ZM231.258 105.175a.255.255 0 0 1 .484 0l.475 1.445a.26.26 0 0 0 .163.163l1.445.475a.255.255 0 0 1 0 .484l-1.445.475a.26.26 0 0 0-.163.163l-.475 1.445a.255.255 0 0 1-.484 0l-.475-1.445a.26.26 0 0 0-.163-.163l-1.445-.475a.255.255 0 0 1 0-.484l1.445-.475a.26.26 0 0 0 .163-.163l.475-1.445ZM59.127 36.631c.278-.841 1.468-.841 1.745 0l1.71 5.2c.092.278.31.495.587.586l5.2 1.71c.841.278.841 1.468 0 1.745l-5.2 1.71a.917.917 0 0 0-.586.587l-1.71 5.2c-.278.841-1.468.841-1.745 0l-1.71-5.2a.917.917 0 0 0-.587-.586l-5.2-1.71c-.841-.278-.841-1.468 0-1.745l5.2-1.71a.917.917 0 0 0 .586-.587l1.71-5.2Z"
                  fill="#410CD9"
                ></path>
                <path
                  d="M121.612 10.28a.409.409 0 0 1 .776 0l.76 2.312c.04.123.137.22.26.26l2.312.76a.409.409 0 0 1 0 .776l-2.312.76a.407.407 0 0 0-.26.26l-.76 2.312a.409.409 0 0 1-.776 0l-.76-2.312a.407.407 0 0 0-.26-.26l-2.312-.76a.409.409 0 0 1 0-.776l2.312-.76a.407.407 0 0 0 .26-.26l.76-2.312ZM249.612 42.28a.409.409 0 0 1 .776 0l.76 2.312c.04.123.137.22.26.26l2.312.76a.409.409 0 0 1 0 .776l-2.312.76a.407.407 0 0 0-.26.26l-.76 2.312a.409.409 0 0 1-.776 0l-.76-2.312a.407.407 0 0 0-.26-.26l-2.312-.76a.409.409 0 0 1 0-.776l2.312-.76a.407.407 0 0 0 .26-.26l.76-2.312ZM198.161 55.245a.357.357 0 0 1 .678 0l.665 2.023c.036.108.12.192.228.228l2.023.665a.357.357 0 0 1 0 .678l-2.023.665a.358.358 0 0 0-.228.228l-.665 2.023a.357.357 0 0 1-.678 0l-.665-2.023a.358.358 0 0 0-.228-.228l-2.023-.665a.357.357 0 0 1 0-.678l2.023-.665a.358.358 0 0 0 .228-.228l.665-2.023Z"
                  fill="#410CD9"
                ></path>
                <path
                  opacity="0.5"
                  d="M177.258 32.175a.255.255 0 0 1 .484 0l.475 1.445a.257.257 0 0 0 .163.163l1.445.475a.255.255 0 0 1 0 .484l-1.445.475a.257.257 0 0 0-.163.163l-.475 1.445a.255.255 0 0 1-.484 0l-.475-1.445a.257.257 0 0 0-.163-.163l-1.445-.475a.255.255 0 0 1 0-.484l1.445-.475a.257.257 0 0 0 .163-.163l.475-1.445ZM147.806.14a.204.204 0 0 1 .388 0l.38 1.156c.02.061.069.11.13.13l1.156.38a.204.204 0 0 1 0 .388l-1.156.38a.205.205 0 0 0-.13.13l-.38 1.156a.204.204 0 0 1-.388 0l-.38-1.156a.205.205 0 0 0-.13-.13l-1.156-.38a.204.204 0 0 1 0-.388l1.156-.38a.205.205 0 0 0 .13-.13l.38-1.156Z"
                  fill="#410CD9"
                ></path>
                <path
                  opacity="0.25"
                  d="M72.258 24.175a.255.255 0 0 1 .484 0l.475 1.445a.255.255 0 0 0 .163.163l1.445.475a.255.255 0 0 1 0 .484l-1.445.475a.255.255 0 0 0-.163.163l-.475 1.445a.255.255 0 0 1-.484 0l-.475-1.445a.255.255 0 0 0-.163-.163l-1.445-.475a.255.255 0 0 1 0-.484l1.445-.475a.255.255 0 0 0 .163-.163l.475-1.445Z"
                  fill="#410CD9"
                ></path>
              </svg>
            </a>
          </div>
          <ul className="hidden lg:flex col-start-11 col-end-12   items-center">
            <a
              href="/about"
              // activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("about");
              }}
              className={
                "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                (activeLink === "about"
                  ? " text-scolor animation-active "
                  : "  hover:text-scolor a")
              }
            >
              About
            </a>

            <a
              activeClass="active"
              // to="Machinery"
              href="/feedback"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("Machinery");
              }}
              className={
                "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                (activeLink === "Machinery"
                  ? " text-scolor animation-active "
                  : "  hover:text-scolor ")
              }
            >
              Feedback
            </a>
            <a
              activeClass="active"
              // to="Machinery"
              href="/calendar"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("Machinery");
              }}
              className={
                "px-4 py-2 mx-2 cursor-pointer animation-hover inline-block relative" +
                (activeLink === "Machinery"
                  ? " text-scolor animation-active "
                  : "  hover:text-scolor ")
              }
            >
              Calendar
            </a>
          </ul>
          {/* login btn */}
          {user && user?.user.user_metadata.email ? (
            <div
              onClick={handleLogout}
              className="col-start-12 col-end-12 font-medium flex justify-end items-center"
            >
              <button className=" border border-pcolor hover:bg-hoverColor text-white font-bold py-2 px-4 rounded-full">
                <span className="text-black-600 mx-2 sm:mx-4 capitalize tracking-wide hover:text-scolor transition-all">
                  Logout
                </span>
              </button>
            </div>
          ) : null}
        </nav>
      </header>
      {/* Mobile Navigation */}

      <nav
        style={{ background: "white" }}
        className="fixed lg:hidden bottom-0 left-0 right-0 z-20 px-2 sm:px-8 shadow-t "
      >
        <div className="bg-grey sm:">
          <ul className="flex w-full justify-around items-center ">
            {/* home */}
            {/* <a
              activeClass="active"
              href="/"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("about");
              }}
              className={
                "mx-1 sm:mx-2  sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "about"
                  ? "  border-scolor text-scolor"
                  : " border-transparent")
              }
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="12"
                height="12"
              >
                <path d="M19 21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001L11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162L23 11.0001L20 11.0001V20.0001C20 20.5524 19.5523 21.0001 19 21.0001ZM6 19.0001H18V9.15757L12 3.70302L6 9.15757V19.0001Z"></path>
              </svg>
              Home
            </a> */}
            {/* Shop */}
            {/* about */}
            <a
              activeClass="active"
              // to="about"
              href="/about"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("about");
              }}
              className={
                "mx-1 sm:mx-2  sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "about"
                  ? "  border-scolor text-scolor"
                  : " border-transparent")
              }
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M13.0605 8.11073L14.4747 9.52494C17.2084 12.2586 17.2084 16.6908 14.4747 19.4244L14.1211 19.778C11.3875 22.5117 6.95531 22.5117 4.22164 19.778C1.48797 17.0443 1.48797 12.6122 4.22164 9.87849L5.63585 11.2927C3.68323 13.2453 3.68323 16.4112 5.63585 18.3638C7.58847 20.3164 10.7543 20.3164 12.7069 18.3638L13.0605 18.0102C15.0131 16.0576 15.0131 12.8918 13.0605 10.9392L11.6463 9.52494L13.0605 8.11073ZM19.778 14.1211L18.3638 12.7069C20.3164 10.7543 20.3164 7.58847 18.3638 5.63585C16.4112 3.68323 13.2453 3.68323 11.2927 5.63585L10.9392 5.98941C8.98653 7.94203 8.98653 11.1079 10.9392 13.0605L12.3534 14.4747L10.9392 15.8889L9.52494 14.4747C6.79127 11.741 6.79127 7.30886 9.52494 4.57519L9.87849 4.22164C12.6122 1.48797 17.0443 1.48797 19.778 4.22164C22.5117 6.95531 22.5117 11.3875 19.778 14.1211Z"></path>
              </svg>
              About
            </a>
            {/* <a
              activeClass="active"
              // to="about"
              href="/sell"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("about");
              }}
              className={
                "mx-1 sm:mx-2  sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "about"
                  ? "  border-scolor text-scolor"
                  : " border-transparent")
              }
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M12.0049 22.0029C6.48204 22.0029 2.00488 17.5258 2.00488 12.0029C2.00488 6.48008 6.48204 2.00293 12.0049 2.00293C17.5277 2.00293 22.0049 6.48008 22.0049 12.0029C22.0049 17.5258 17.5277 22.0029 12.0049 22.0029ZM12.0049 20.0029C16.4232 20.0029 20.0049 16.4212 20.0049 12.0029C20.0049 7.58465 16.4232 4.00293 12.0049 4.00293C7.5866 4.00293 4.00488 7.58465 4.00488 12.0029C4.00488 16.4212 7.5866 20.0029 12.0049 20.0029ZM8.50488 14.0029H14.0049C14.281 14.0029 14.5049 13.7791 14.5049 13.5029C14.5049 13.2268 14.281 13.0029 14.0049 13.0029H10.0049C8.62417 13.0029 7.50488 11.8836 7.50488 10.5029C7.50488 9.12222 8.62417 8.00293 10.0049 8.00293H11.0049V6.00293H13.0049V8.00293H15.5049V10.0029H10.0049C9.72874 10.0029 9.50488 10.2268 9.50488 10.5029C9.50488 10.7791 9.72874 11.0029 10.0049 11.0029H14.0049C15.3856 11.0029 16.5049 12.1222 16.5049 13.5029C16.5049 14.8836 15.3856 16.0029 14.0049 16.0029H13.0049V18.0029H11.0049V16.0029H8.50488V14.0029Z"></path>
              </svg>
              Sell
            </a> */}
            {/* help */}
            <a
              activeClass="active"
              href="/feedback"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("shop");
              }}
              className={
                "mx-1 sm:mx-2  sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "shop"
                  ? "  border-scolor text-scolor"
                  : " border-transparent ")
              }
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="12"
                height="12"
              >
                <path d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM4 18.3851L5.76282 17H20V5H4V18.3851ZM11 13H13V15H11V13ZM11 7H13V12H11V7Z"></path>
              </svg>
              Feedback
            </a>
            <a
              activeClass="active"
              href="/calendar"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={() => {
                setActiveLink("testimoni");
              }}
              className={
                "mx-1 sm:mx-2  sm:px-4 py-2 flex flex-col items-center text-xs border-t-2 transition-all " +
                (activeLink === "testimoni"
                  ? "  border-scolor text-scolor"
                  : " border-transparent ")
              }
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path>
              </svg>
              Calendar
            </a>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;
